import Layout from "../components/layout/layout";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import PageHero from "../components/pageHero/pageHero";
import JSONData from "../../content/candidatesPageHeroData.json";
import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import DiversitySection from "../components/ourServices/diversitySection/diversitySection";
import * as styles from "../styles/candidates.module.scss";
import LatestNews from "../components/latestNews/latestNews";
import {Helmet} from "react-helmet";


export default function CandidatesPage() {

    const data = useStaticQuery(graphql`{
        loxoJobs: allLoxoJob(sort: {fields: created_at, order: DESC}, limit: 4) {
            nodes {
              company {
                logo_large_url,
                name
              }
              title
              city
              country_code
              salary
              job_type {
                name
              }
              public_url
            }
          }
        }`);

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>Candidates - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <PageHero
                text={JSONData.text}
                sectionTitle={JSONData.sectionTitle}
                header={JSONData.header}
                loxoJobs={data.loxoJobs}
                displayLoxoJobsTitle={true}
            />
            <div className={styles.tileWrapper}>
                <DiversitySection/>
            </div>
            {/**<div className={styles.newsWrapper}>
                <LatestNews title={"All the latest news & insights"}
                            text={"Looking for some tips and advice to help you thrive in your career? Check out our latest articles."}/>
    </div>**/}
        </Layout>
    </>
}
